/* eslint-disable import/no-anonymous-default-export */
export default {
  baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  baseUrlApollo: process.env.NEXT_PUBLIC_API_BASE_URL_APOLLO,
  algoliasearchAppId:
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APPID ?? "L6HU33HKS0",
  algoliasearchApiKey:
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APIKEY ??
    "8fa6652dfc7f2225015b6d6613466a82",
  basePath: process.env.NEXT_PUBLIC_BASE_PATH,
  sentryDsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ??
    "https://b4536eb27ae433ac91a26f3b6861d2fe@o35632.ingest.sentry.io/4505868094144512",
  mockupApollo: true,
  devToken: process.env.NEXT_PUBLIC_DEV_TOKEN,
  /// TODO
  mixpanelToken: "",
  enableStatTracking: false,
  accountsBaseURL: ""
}
